.information-box {
    padding-left: 15px;
    padding-right: 15px;
}

.pa {
    padding-left: 10px;
}

.overview-item-title {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
}

.overview-item-text {
    font-size: 17px !important;
}

.first-box {
    margin-top: 20px !important;
}

