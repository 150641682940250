@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
.dot {
    height: 10px;
    width: 10px;    
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.align-vertical {
    vertical-align: middle;
    color: #979797;
}

.green {
    background-color: green;
}

.new {
    background-color: #559AAA;
}

.orange {
    background-color: orange;
}

.red {
    background-color: red;
}
.style_scrollArea__1_NnX {
  min-height: 100vh;
  padding: 56px 0 141px 0;
  overflow-y: scroll;
  overflow-x: hidden; }

.style_informationBox__yji2G {
  padding-left: 15px;
  padding-right: 15px; }

.style_separatorLine__n8BsW {
  padding: 10px 0 10px;
  border-bottom: 1px solid #e6e6e6; }

.review-images-gallery {
    padding: 15px;
}

.image-gallery-bullets {
    bottom: -20px !important;
}

.image-gallery-bullets .image-gallery-bullet {
    box-shadow: none !important;
    background-color: #E6E6E6 !important;
    border: none !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
    background: #0c0c0c !important;
}

.detail-name {
    font-weight: bold;
    color: #979797;
}

.review-category-title {
    font-size: 11px;
    font-weight: bold;
}

.review-category-text {
    color: #979797;
}

.review-details-grid {
    padding-bottom: 30px;
}

.review-text {
    font-size: 14px;
}

/* .carousel .slide img {
    vertical-align: unset !important;
} */
.information-box {
    padding-left: 15px;
    padding-right: 15px;
}

.pa {
    padding-left: 10px;
}

.overview-item-title {
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
}

.overview-item-text {
    font-size: 17px !important;
}

.first-box {
    margin-top: 20px !important;
}


.services-separator-line {
    border-bottom: solid 1px #E6E6E6;
}

.formGroup {
    margin: 10px !important;
    margin-right: 20px !important;
    margin-left: 35px !important;
}

.title {
    font-size: 14 !important;
    margin: 10px !important;
}

.borderPadding {
  padding: 10px !important;
}

.subtitle {
  font-size: 14 !important;
}

.icon {
    margin-right: 10px !important;
}

.checkboxLabelColor {
  color: 'grey' !important;
}

.services-title {
  font-size: 11px;
  font-weight: bold;
}

.services-description-text {
  margin-top: 10px;
}

.review-text {
  font-size: 15px;
}

.request-first-item {
  padding-top: 30px;
}

.services-text {
  color: #8C8C8C;
  font-size: 15px;
}
.style_imagesGallery__2vpzb {
  padding: 15px;
  margin-bottom: 5px; }

.style_cameraButton__2xQIj {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 100% 0;
  height: 0;
  background: #f3f3f3; }
  .style_cameraButton__2xQIj .style_showChamera__3ErhC {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    padding: 0;
    border: 0;
    background: none;
    font-family: "Roboto", sans-serif;
    font-size: 15px; }
    .style_cameraButton__2xQIj .style_showChamera__3ErhC:active, .style_cameraButton__2xQIj .style_showChamera__3ErhC:focus {
      outline: 0; }
    .style_cameraButton__2xQIj .style_showChamera__3ErhC .style_circle__2AFkB {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 118px;
      height: 118px;
      margin: 0 auto;
      padding: 10%;
      border-radius: 50%; }

.style_camera__1h0x1 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 1101; }
  .style_camera__1h0x1 .style_cameraImage__COXXt {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 0;
    padding-bottom: calc(100% - 30px);
    overflow: hidden; }
    .style_camera__1h0x1 .style_cameraImage__COXXt video {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .style_camera__1h0x1 .style_shutterButton__L_Y1R {
    position: absolute;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid #000000;
    box-shadow: 0 0 0 2px #ffffff;
    transition: all .3s; }
    .style_camera__1h0x1 .style_shutterButton__L_Y1R:hover {
      opacity: .75; }
    .style_camera__1h0x1 .style_shutterButton__L_Y1R:active, .style_camera__1h0x1 .style_shutterButton__L_Y1R:focus {
      outline: 0; }
  .style_camera__1h0x1 .style_closeCamera__1D6-g {
    position: absolute;
    bottom: 28px;
    right: 28px;
    height: 32px;
    padding: 0 16px;
    background: none;
    border: 1px solid #ffffff;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1;
    color: #ffffff; }
    .style_camera__1h0x1 .style_closeCamera__1D6-g:active, .style_camera__1h0x1 .style_closeCamera__1D6-g:focus {
      outline: 0; }

.style_cameraBox__1QY4e {
  position: relative;
  width: 100%;
  padding: 0 0 100% 0;
  height: 0;
  background: #f3f3f3; }
  .style_cameraBox__1QY4e .style_image__uyy1E {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .style_cameraBox__1QY4e .style_image__uyy1E img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%; }

.style_center__2utRl {
  text-align: -webkit-center; }

.style_cameraTextBox__1sIJJ {
  margin-top: 10px; }

.style_checkStateBox__2izbI {
  width: 100%; }

.style_checkOptions__1KyXp {
  display: flex;
  justify-content: space-around; }

.style_controlLabel__sQC6E {
  margin-right: 5px !important;
  font-size: 15px !important;
  color: #979797; }

.style_reportCategoryTitle__1IYOC {
  font-size: 11px;
  font-weight: bold; }

.style_reportObservations__3sfiq {
  margin-top: 9px;
  font-size: 15px;
  color: #979797; }

.carousel .slide {
  background-color: #ffffff; }

.carousel .control-dots {
  bottom: -30px !important;
  position: unset !important;
  padding-left: 0px !important; }
  .carousel .control-dots .dot {
    transition: unset;
    opacity: unset;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #e6e6e6; }
    .carousel .control-dots .dot:active, .carousel .control-dots .dot:focus {
      outline: 0; }
    .carousel .control-dots .dot.selected {
      opacity: 1;
      background: #383838; }

.style_roomSteps__3AJ9L {
  background-color: #3b5c23;
  min-height: 100vh;
  color: white;
  overflow: hidden; }

.style_hi__1ZK_d {
  font-size: 28px;
  font-weight: bold;
  margin: 0px; }

.style_header__2azOy {
  min-width: 300px;
  width: 79vw;
  margin: 20vh 0 0px 8vw;
  font-size: 19px; }
  .style_header__2azOy p {
    margin: 10px 0 0;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.5; }
    .style_header__2azOy p.style_big__2sV6e {
      margin-top: 0;
      font-size: 35px;
      font-weight: 500;
      line-height: 1; }

.style_roomName__1Sv-H {
  display: inline-block;
  border: 1px solid #ffffff;
  padding: 5px 5px 3px; }

.style_bottomSection__oH943 {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 0;
  width: 100%;
  height: calc(53px + 8vh);
  padding: 0 0 0 8vw;
  box-sizing: border-box; }

.style_pagination__nJuQa {
  margin: 0;
  padding: 0;
  font-size: 23px; }

.style_startButton__2Q7hA {
  position: absolute;
  bottom: 4vh;
  right: 6vw;
  width: 170px;
  height: 53px;
  border-radius: 50px;
  background: #fb9b29;
  text-transform: none;
  color: white; }

.style_label__3Q6rp {
  font-size: 18px; }

.style_start__2lhdt {
  background-color: #193860;
  min-height: 100vh;
  color: white;
  overflow: hidden; }

.style_hi__30fhc {
  font-size: 56px;
  font-weight: bold;
  margin: 0px; }

.style_header__2DamJ {
  min-width: 278px;
  width: 67vw;
  margin: 0;
  margin: 20vh 0 0px 8vw;
  font-size: 28px; }
  .style_header__2DamJ p {
    margin: 0;
    font-size: 30px;
    font-weight: 300; }
    .style_header__2DamJ p.style_big__2BC-D {
      margin-top: 0;
      font-size: 56px;
      font-weight: 500;
      line-height: 1; }

.style_noMargin__GgYFH {
  margin: 0px; }

.style_bottomSection__1L5MQ {
  position: absolute;
  bottom: 4vh;
  right: 6vw; }

.style_startButton__3yBO4 {
  width: 170px;
  height: 53px;
  border-radius: 50px;
  background: #fb9b29;
  text-transform: none;
  color: white; }

.style_label__3SraJ {
  font-size: 18px; }

.Center-box {
    text-align: center;
}

.Title-text {
    font-size: 15px;
}
.App-bar {
    border-bottom: solid 1px #E6E6E6;
}
.style_loading__w1YfU {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }

.style_text__3y6dm {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 8px;
  font-weight: 600;
  text-align: center; }

.style_children__2U2Cb {
  position: absolute;
  top: calc(50% + 56px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 500;
  text-align: center; }

.style_finish__1rOVh {
  background-color: #193860;
  min-height: 100vh;
  color: white;
  overflow: hidden; }

.style_hi__idomZ {
  font-size: 56px;
  font-weight: bold;
  margin: 0px; }

.style_header__1ikXC {
  margin: 20vh 8vw 0px;
  font-size: 28px; }
  .style_header__1ikXC p {
    margin: 0;
    font-size: 30px;
    font-weight: 300; }
    .style_header__1ikXC p.style_big__3el9R {
      margin-top: 0;
      font-size: 56px;
      font-weight: 500;
      line-height: 1; }
    .style_header__1ikXC p.style_small__2guJX {
      margin-top: 24px;
      font-size: 20px;
      line-height: 1.4;
      opacity: 0.7; }

.style_noMargin__2xCdd {
  margin: 0px; }

.style_bottomSection__2d-uA {
  position: absolute;
  bottom: 4vh;
  right: 6vw; }

.style_startButton__zzJoN {
  background: #fb9b29;
  color: white;
  text-transform: none;
  width: 170px;
  height: 53px; }

.style_label__1Q6fp {
  font-size: 18px; }


html {
  overflow: hidden;
  height: 100%; }

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

