.dot {
    height: 10px;
    width: 10px;    
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}

.align-vertical {
    vertical-align: middle;
    color: #979797;
}

.green {
    background-color: green;
}

.new {
    background-color: #559AAA;
}

.orange {
    background-color: orange;
}

.red {
    background-color: red;
}