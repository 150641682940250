.imagesGallery {
  padding: 15px;
  margin-bottom: 5px;
}

.cameraButton {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 100% 0;
  height: 0;
  background: #f3f3f3;

  .showChamera {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    padding: 0;
    border: 0;
    background: none;
    font-family: "Roboto", sans-serif;
    font-size: 15px;

    &:active,
    &:focus {
      outline: 0;
    }

    .circle {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 118px;
      height: 118px;
      margin: 0 auto;
      padding: 10%;
      border-radius: 50%;
    }
  }
}

.camera {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 1101;

  .cameraImage {
    position: absolute;
    top: 50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    height: 0;
    padding-bottom: calc(100% - 30px);
    overflow: hidden;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .shutterButton {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 48px;
    height: 48px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 2px solid #000000;
    box-shadow: 0 0 0 2px #ffffff;
    transition: all .3s;

    &:hover {
      opacity: .75;
    }

    &:active,
    &:focus {
      outline: 0;
    }
  }

  .closeCamera {
    position: absolute;
    bottom: 28px;
    right: 28px;
    height: 32px;
    padding: 0 16px;
    background: none;
    border: 1px solid #ffffff;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1;
    color: #ffffff;

    &:active,
    &:focus {
      outline: 0;
    }
  }
}

.cameraBox {
  position: relative;
  width: 100%;
  padding: 0 0 100% 0;
  height: 0;
  background: #f3f3f3;

  .image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
}

.center {
  text-align: -webkit-center;
}

.cameraTextBox {
  margin-top: 10px;
}

.checkStateBox {
  width: 100%;
}

.checkOptions {
  display: flex;
  justify-content: space-around;
}

.controlLabel {
  margin-right: 5px !important;
  font-size: 15px !important;
  color: #979797;
}

.reportCategoryTitle {
  font-size: 11px;
  font-weight: bold;
}

.reportObservations {
  margin-top: 9px;
  font-size: 15px;
  color: #979797;
}
