.carousel {
  .slide {
    background-color: #ffffff;
  }

  .control-dots {
    bottom: -30px !important;
    position: unset !important;
    padding-left: 0px !important;

    .dot {
      transition: unset;
      opacity: unset;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: #e6e6e6;

      &:active,
      &:focus {
        outline: 0;
      }

      &.selected {
        opacity: 1;
        background: #383838;
      }
    }
  }
}
