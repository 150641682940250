.roomSteps {
  background-color: rgb(59, 92, 35);
  min-height: 100vh;
  color: white;
  overflow: hidden;
}

.hi {
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
}

.header {
  min-width: 300px;
  width: 79vw;
  margin: 20vh 0 0px 8vw;
  font-size: 19px;

  p {
    margin: 10px 0 0;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.5;

    &.big {
      margin-top: 0;
      font-size: 35px;
      font-weight: 500;
      line-height: 1;
    }
  }
}

.roomName {
  display: inline-block;
  border: 1px solid #ffffff;
  padding: 5px 5px 3px;
}

.bottomSection {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 0;
  width: 100%;
  height: calc(53px + 8vh);
  padding: 0 0 0 8vw;
  box-sizing: border-box;
}

.pagination {
  margin: 0;
  padding: 0;
  font-size: 23px;
}

.startButton {
  position: absolute;
  bottom: 4vh;
  right: 6vw;
  width: 170px;
  height: 53px;
  border-radius: 50px;
  background: #fb9b29;
  text-transform: none;
  color: white;
}

.label {
  font-size: 18px;
}
