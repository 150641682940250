.review-images-gallery {
    padding: 15px;
}

.image-gallery-bullets {
    bottom: -20px !important;
}

.image-gallery-bullets .image-gallery-bullet {
    box-shadow: none !important;
    background-color: #E6E6E6 !important;
    border: none !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
    background: #0c0c0c !important;
}

.detail-name {
    font-weight: bold;
    color: #979797;
}

.review-category-title {
    font-size: 11px;
    font-weight: bold;
}

.review-category-text {
    color: #979797;
}

.review-details-grid {
    padding-bottom: 30px;
}

.review-text {
    font-size: 14px;
}

/* .carousel .slide img {
    vertical-align: unset !important;
} */