.scrollArea {
  min-height: 100vh;
  padding: 56px 0 141px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.informationBox {
  padding-left: 15px;
  padding-right: 15px;
}

.separatorLine {
  padding: 10px 0 10px;
  border-bottom: 1px solid #e6e6e6;
}
