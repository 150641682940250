.start {
  background-color: #193860;
  min-height: 100vh;
  color: white;
  overflow: hidden;
}

.hi {
  font-size: 56px;
  font-weight: bold;
  margin: 0px;
}

.header {
  min-width: 278px;
  width: 67vw;
  margin: 0;
  margin: 20vh 0 0px 8vw;
  font-size: 28px;

  p {
    margin: 0;
    font-size: 30px;
    font-weight: 300;

    &.big {
      margin-top: 0;
      font-size: 56px;
      font-weight: 500;
      line-height: 1;
    }
  }
}

.noMargin {
  margin: 0px;
}

.bottomSection {
  position: absolute;
  bottom: 4vh;
  right: 6vw;
}

.startButton {
  width: 170px;
  height: 53px;
  border-radius: 50px;
  background: #fb9b29;
  text-transform: none;
  color: white;
}

.label {
  font-size: 18px;
}
