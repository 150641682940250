.services-separator-line {
    border-bottom: solid 1px #E6E6E6;
}

.formGroup {
    margin: 10px !important;
    margin-right: 20px !important;
    margin-left: 35px !important;
}

.title {
    font-size: 14 !important;
    margin: 10px !important;
}

.borderPadding {
  padding: 10px !important;
}

.subtitle {
  font-size: 14 !important;
}

.icon {
    margin-right: 10px !important;
}

.checkboxLabelColor {
  color: 'grey' !important;
}

.services-title {
  font-size: 11px;
  font-weight: bold;
}

.services-description-text {
  margin-top: 10px;
}

.review-text {
  font-size: 15px;
}

.request-first-item {
  padding-top: 30px;
}

.services-text {
  color: #8C8C8C;
  font-size: 15px;
}