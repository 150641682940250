@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
